import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { ApolloProvider as ApolloProvider1 } from 'react-apollo';
import React, { ReactNode } from 'react';

const uri = process.env.NODE_ENV === 'production' ? 'https://web-classic-tinker.vercel.app/graphql' : 'http://localhost:3000/graphql';

export const getClient = (fetch) => (
  new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      new RetryLink({
        attempts: {
          max: 3,
        },
      }),
      new HttpLink({
        fetch,
        uri,
      }),
    ]),
  })
);

interface Props {
  children: ReactNode;
  fetch: any;
}

export default function ApolloProvider(props: Props) {
  const { children, fetch } = props;
  const client = getClient(fetch);

  return (
    <ApolloProvider1 client={client}>
      {children}
    </ApolloProvider1>
  );
}
