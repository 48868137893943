export function isNode() {
  return new Function('try {return this===global;}catch(e){return false;}')();
}

export function disableReactDevTools() {
  const noop = (): void => undefined;
  // @ts-ignore
  const DEV_TOOLS = window.__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof DEV_TOOLS === 'object') {
    Object.entries(DEV_TOOLS).forEach(([key, value]) => {
      DEV_TOOLS[key] = typeof value === 'function' ? noop : null;
    });
  }
}

export function upperCaseDashes(input) {
  return input.match(/[a-z]+/g).join('-');
}

export function isShareSupported() {
  return !isNode() && ('share' in navigator);
}

export function shareUrl() {
  if (!isShareSupported()) {
    return;
  }
  let url = document.location.href;
  const canonicalElement: HTMLLinkElement = document.querySelector('link[rel=canonical]');
  if (canonicalElement !== null) {
    url = canonicalElement.href;
  }
  // @ts-ignore
  navigator.share({ url });
}

export function getRaceImage(race, gender) {
  return require(`../../../images/race_${race}_${gender}.jpg`);
}

export function sortNames(a, b) {
  return a.localeCompare(b);
}

function isCryptoSupported() {
  // @ts-ignore
  return window.crypto && crypto.subtle && window.TextEncoder;
}

function bufferToHex(buff) {
  return [].map.call(new Uint8Array(buff), b => (`00${b.toString(16)}`).slice(-2)).join('');
}

export async function hashText(text) {
  if (!isCryptoSupported()) {
    return '';
  }
  const data = new TextEncoder().encode(text);
  const buffer = await crypto.subtle.digest({ name: 'SHA-256' }, data);
  return bufferToHex(buffer);
}

export function noop() {}

export function leftFillNum(num, width) {
  return num
    .toString()
    .padStart(width, 0);
}
