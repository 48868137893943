import React, {
  ReactNode, useEffect, useReducer, useState,
} from 'react';
import { ThemeProvider } from 'styled-components';
// eslint-disable-next-line no-unused-vars
import * as constants from '../../names/utils/constants';
import storage from '../utils/storage';
import GlobalStyle from '../styles/GlobalStyle';
import { themes, GlobalContext } from '../context/global-context';
import { initialSavedNames, reducerSavedNames } from '../../names/context/names-saved-reducer';

interface Props {
  children: ReactNode;
}

export default function Wrapper(props: Props) {
  const { children } = props;
  const [state, dispatch] = useReducer(reducerSavedNames, initialSavedNames);
  const [theme, setTheme] = useState(themes.alliance);

  useEffect(() => {
    // set horde theme
    setTimeout(() => {
      const isHordeTheme = storage.get('horde', '') === constants.HORDE_HASH;
      if (isHordeTheme) {
        setTheme(themes.horde);
      }
    });
  }, [theme.name]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalContext.Provider value={{
        state, dispatch, setTheme, theme,
      }}
      >
        <GlobalStyle />
        {children}
      </GlobalContext.Provider>
    </ThemeProvider>
  );
}
