import { isNode } from './utils';

export default {
  get(key, missing) {
    try {
      if (isNode()) {
        return missing;
      }
      const item = localStorage.getItem(key) || missing;
      return JSON.parse(item);
    } catch (e) {
      return missing;
    }
  },

  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
};
