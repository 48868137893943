import { rgba } from 'polished';
import React from 'react';
import { SavedNamesAction, SavedNameState } from '../../names/context/names-saved-reducer';

const BASE_COLORS = {
  black: '#000000',
  gold: '#ffd700',
  red: '#ff78ae',
  white: '#ffffff',
};

function getColors({
  primary, primaryDark, primaryLight, secondaryLight, secondaryDark,
}) {
  return {
    ...BASE_COLORS,
    primary,
    primaryAlpha: rgba(primary, 0.9),
    primaryDark,
    primaryDarkAlpha: rgba(primaryDark, 0.5),
    primaryLight,
    primaryLightAlpha: rgba(primaryLight, 0.5),
    secondaryLight,
    secondaryLightAlpha: rgba(secondaryLight, 0.5),
    secondaryDark,
    secondaryDarkAlpha: rgba(secondaryDark, 0.55),
  };
}

export const themes = {
  alliance() {
    const colors = {
      primary: '#2a2c38',
      primaryDark: '#44516d',
      primaryLight: '#c1ccde',
      secondaryLight: '#e8bd00',
      secondaryDark: '#17191f',
    };

    return {
      name: 'alliance',
      ...getColors(colors),
    };
  },
  horde() {
    const colors = {
      primary: '#2b2828',
      primaryDark: '#b52828',
      primaryLight: '#f2d5db',
      secondaryLight: '#f5772b',
      secondaryDark: '#181818',
    };

    return {
      name: 'horde',
      ...getColors(colors),
      primaryDarkAlpha: rgba(colors.primaryDark, 0.45),
    };
  },
};

// global context
export interface GlobalInterface {
  setTheme: any;
  theme: any;
  state: SavedNameState;
  dispatch(action: SavedNamesAction): void;
}

export const GlobalContext = React.createContext<GlobalInterface | null>(null);
