import { createGlobalStyle, css } from 'styled-components';
import { em } from 'polished';

export default createGlobalStyle`
  body, html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    font-family: arial, verdana, sans-serif;
    background-color: var(--primary-color);
    color: white;
  }

  body, html, #___gatsby, div[role="group"][tabindex] {
    height: 100%;
  }

  body {
    background-color: var(--primary-color);
    transition: background-color 1000ms linear;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid var(--secondaryLight-color);
    margin: 1em 0;
    padding: 0;
  }

  h1 {
    margin: 0;
    font-family: 'Alegreya Sans', sans-serif;
    font-size: 1.6em;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 5px;
    font-family: 'Alegreya Sans', sans-serif;
    font-size: 1.5em;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  button {
    touch-action: none;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: var(--secondaryLight-color);

    &:hover {
      color: var(--secondaryLightAlpha-color)
    }
  }

  ${(props: any) => css`
    :root {
      /* base colors */
      --black-color: ${props.theme.black};
      --white-color: ${props.theme.white};
      --gold-color: ${props.theme.gold};
      --red-color: ${props.theme.red};
      /* theme colors */
      --primary-color: ${props.theme.primary};
      --primaryAlpha-color: ${props.theme.primaryAlpha};
      --primaryDark-color: ${props.theme.primaryDark};
      --primaryDarkAlpha-color: ${props.theme.primaryDarkAlpha};
      --primaryLight-color: ${props.theme.primaryLight};
      --primaryLightAlpha-color: ${props.theme.primaryLightAlpha};
      --secondaryLight-color: ${props.theme.secondaryLight};
      --secondaryLightAlpha-color: ${props.theme.secondaryLightAlpha};
      --secondaryDark-color: ${props.theme.secondaryDark};
      --secondaryDarkAlpha-color: ${props.theme.secondaryDarkAlpha};
    }
  `}

  ::selection {
    background: var(--secondaryLightAlpha-color);
  }

  .page-container {
    max-width: 1064px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
  }

  .page-padding {
    padding: ${em(20)} ${em(20)} ${em(20)};
  }

  .page-padding-height {
    padding: ${em(20)} 0;
  }

  .page-padding-width {
    padding: 0 ${em(20)};
  }
  
  .content {
    display: grid;
    grid-template-rows: max-content;
    grid-gap: ${em(20)};
    max-width: 1144px;
  }

  @keyframes fadeInFromNone {
    0% { display: none; opacity: 0; }
    1% { display: block; opacity: 0; }
    100% { display: block; opacity: 1; }
  }
`;
