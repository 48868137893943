// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-location-template-tsx": () => import("/opt/build/repo/src/templates/location-template.tsx" /* webpackChunkName: "component---src-templates-location-template-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bis-tsx": () => import("/opt/build/repo/src/pages/bis.tsx" /* webpackChunkName: "component---src-pages-bis-tsx" */),
  "component---src-pages-copyright-notices-tsx": () => import("/opt/build/repo/src/pages/copyright-notices.tsx" /* webpackChunkName: "component---src-pages-copyright-notices-tsx" */),
  "component---src-pages-ding-tsx": () => import("/opt/build/repo/src/pages/ding.tsx" /* webpackChunkName: "component---src-pages-ding-tsx" */),
  "component---src-pages-flight-master-tsx": () => import("/opt/build/repo/src/pages/flight-master.tsx" /* webpackChunkName: "component---src-pages-flight-master-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-locations-tsx": () => import("/opt/build/repo/src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-name-generator-tsx": () => import("/opt/build/repo/src/pages/name-generator.tsx" /* webpackChunkName: "component---src-pages-name-generator-tsx" */)
}

