/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { disableReactDevTools } from './src/client/common/utils/utils';
import Wrapper from './src/client/common/layout/Wrapper';
import Provider from './src/shared/api/apollo-provider';

const isProd = process.env.NODE_ENV === 'production';

export const wrapRootElement = ({ element }) => (
  <Provider fetch={window.fetch}>
    <Wrapper>
      {element}
    </Wrapper>
  </Provider>
);

export const shouldUpdateScroll = ({
  routerProps: { location },
}) => {
  const { pathname } = location;
  return pathname === '/';
};

if (isProd) {
  disableReactDevTools();
}
