import storage from '../../common/utils/storage';

export type SavedNameState = Set<string>;

export interface SavedNamesAction {
  name: string;
  type: 'toggle' | 'add' | 'delete';
}

// get names from localStorage
export const initialSavedNames: Set<string> = new Set(storage.get('names', []));

export function reducerSavedNames(currentState: SavedNameState = initialSavedNames, action: SavedNamesAction) {
  const state = new Set(currentState);
  switch (action.type) {
    case 'add': {
      state.add(action.name);
      break;
    }
    case 'delete': {
      state.delete(action.name);
      break;
    }
    case 'toggle': {
      if (state.has(action.name)) state.delete(action.name); else state.add(action.name);
      break;
    }
    default:
      break;
  }
  storage.set('names', Array.from(state));
  return state;
}
